import { useRouter as usePageRouter } from 'next/router';
import { isPc, isHlpWebview } from '@hanwhalife/shared-utils/bomUtil';
import { getAddingInitQueryStringUrl } from '@hanwhalife/shared-utils/urlUtil';
import { bridge } from '@hanwhalife/bridge';
import { YesNo } from '@hanwhalife/ts-types';
import { IMoveWebPageArgs, IWebPushOptions, Url, TransitionOptions } from './router.types';
import { BLOCK_LIST } from './routing-info';
import { getFrontendServiceFromUrl } from './router.utils';

/**
 * 이동 처리는 모두 여기서 처리
 * @returns
 */
export const useHlpRouter = () => {
  const pageRouter = usePageRouter();

  class HlpRouter {}

  const customFunctionMap = {
    /**
     * 커스텀 push
     * @param url
     * @param as
     * @param options
     * @returns
     */
    push(url: Url, as?: Url, options?: TransitionOptions) {
      // custom logic 필요시 작성

      return pageRouter.push(url, as, options);
    },

    /**
     * 커스텀 replace
     * @param url
     * @param as
     * @param options
     * @returns
     */
    replace(url: Url, as?: Url, options?: TransitionOptions) {
      // custom logic 필요시 작성

      return pageRouter.replace(url, as, options);
    },

    /**
     * login page 이동처리 (needRedirectUrl에 true를 넣으면 로그인 후 redirectUrl로 랜딩됩니다.)
     * redirectUrl 미 입력시 현재
     * @param param0
     * @returns
     */
    goToLoginPage(options?: { needRedirectUrl?: boolean; redirectUrl?: string; replace?: boolean }) {
      const needRedirectUrl = options?.needRedirectUrl ?? true;
      const replace = options?.replace ?? false;
      const redirectUrl = options?.redirectUrl ?? location.href;

      const pathname = `/app/login${needRedirectUrl ? `?redirectUrl=${encodeURIComponent(redirectUrl)}` : ''}`;

      if (replace) {
        return pageRouter.replace(pathname);
      }

      return pageRouter.push(pathname);
    },

    /**
     * full url을 입력 했을때 이동처리
     * 같은 도메인이면 push, 상품이면 href, 이외에는 새창
     * @param Full URL
     * @returns
     */
    moveLink(url: string) {
      const appCode = getFrontendServiceFromUrl(url);
      // 이동할 URL
      const targetUrl = this.getLink(url) as string;

      // 우리 웹이면 라우터 이동
      if (appCode === 'hlp-direct') {
        pageRouter.push(targetUrl);

        return;
      }

      // 상품 프론트엔드면 href로 이동
      if (appCode === 'hlp-product') {
        window.location.href = targetUrl;

        return;
      }

      // 나머지 새창
      window.open(targetUrl, '_blank', 'noopener');
    },

    /**
     * https://가 들어가 있는 full url -> 홈페이지, 온슈어, 다이렉트웹 이동처리
     * @param url
     * @returns
     */
    getLink(url: string) {
      const appCode = getFrontendServiceFromUrl(url);

      // 우리 웹이면 pathname 이후
      if (appCode === 'hlp-direct') {
        const { pathname, search } = new URL(url);

        return pathname + search;
      }

      // 상품 URL 구하기
      if (appCode === 'hlp-product') {
        return getAddingInitQueryStringUrl(url);
      }

      // 나머지 (홈페이지)
      return isPc() ? url.replaceAll('https://m.', 'https://www.').replaceAll('https://qam.', 'https://qa.') : url;
    },

    /**
     * a tag target 리턴
     * @param url
     * @returns
     */
    getTarget(url: string) {
      if (url) {
        const appCode = getFrontendServiceFromUrl(url);
        if (appCode === 'hlp-direct') {
          return undefined;
        }

        if (appCode === 'hlp-product') {
          return '_self';
        }

        return '_blank';
      } else {
        return '_self';
      }
    },

    /**
     * a tag rel 리턴
     * @param url
     * @returns
     */
    getRel(url: string) {
      return this.getTarget(url) === '_blank' ? 'noopener' : undefined;
    },

    /**
     * a tag title 리턴
     * @param url
     * @returns
     */
    getTitle(url: string) {
      return this.getTarget(url) === '_blank' ? '새창열림' : undefined;
    },

    /**
     * 뒤로가기(for Web, Native)
     * @param args
     */
    backScreen(args: { reload?: YesNo; byHardware?: boolean } = { reload: 'N', byHardware: false }) {
      const { asPath } = pageRouter;

      if (isHlpWebview()) {
        // 뒤로가기 막아야 하는 페이지에서는 return
        if (args.byHardware && BLOCK_LIST.some((item) => asPath.includes(item))) {
          console.log(`BLOCK---LIST: ${asPath}`);

          return;
        }

        return bridge.webBack({ reload: args.reload as YesNo });
      }

      return pageRouter.back();
    },

    /**
     * 화면 닫기(for Native)
     * @param args
     */
    //+ 사유: Web, App 같이 쓸 필요 없음
    closeScreen(args: { reload: YesNo } = { reload: 'N' }) {
      if (isHlpWebview()) {
        return bridge.webClose({ reload: args.reload });
      }

      return window.close();
    },

    /**
     * 화면 이동(for Web, Native)
     * @param args
     * @param pushOptions
     */
    //! TODO: 삭제예정 (Deprecated)
    //+ 사유: Web, App 같이 쓸 필요도 없고 신규 plugin 사용 예정
    moveWebPage(args: IMoveWebPageArgs, pushOptions?: Omit<IWebPushOptions, 'url'>) {
      // 웹이거나 라우터 사용여부가 Y일경우 라우터로 이동
      if (args.path && (!isHlpWebview() || args.routerUseYn === 'Y')) {
        return pageRouter.push(args.path, pushOptions?.as, pushOptions?.options);
      }

      return bridge.moveWebPage({ ...args });
    },

    /**
     * 메인탭 화면 이동(only Native)
     * @param args
     * @param pushOptions
     */
    //! TODO: 삭제예정 (Deprecated)
    //+ 사유: Web, App 같이 쓸 필요도 없고 신규 plugin 사용 예정
    moveMainTabPage(tab: '0' | '1' | '2') {
      this.moveWebPage({ channelType: 'main', path: tab });
    },

    /**
     * 로그인페이지 이동(for Web, Native)
     * @param args
     */
    //! TODO: 삭제예정 (Deprecated)
    //+ 사유: Web, App 같이 쓸 필요 없음
    loginPage() {
      if (isHlpWebview()) {
        return bridge.loginPage();
      }

      // TODO: 로그인 페이지 이동
      //return pageRouter.push('/login');
      return;
    },
    /**
     * 회원가입페이지 이동(for Web, Native)
     * @param args
     */
    //! TODO: 삭제예정
    //+ 사유: Web, App 같이 쓸 필요 없음
    joinPage() {
      if (isHlpWebview()) {
        return bridge.joinPage();
      }

      // TODO: 회원가입 페이지 이동
      //return pageRouter.push('/login');
      return;
    }
  };

  Object.assign(HlpRouter.prototype, pageRouter);
  Object.assign(HlpRouter.prototype, customFunctionMap);

  return new HlpRouter() as typeof pageRouter & typeof customFunctionMap;
};
