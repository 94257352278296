import styled from '@hanwhalife/shared-libs/emotion/styled';
import { Box } from '@hanwhalife/shared-libs/design-system';
import { css } from '@hanwhalife/shared-libs/emotion/react';

export const StyledFileDownloadButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: flex-start;
  overflow: hidden;
`;

export const StyledHashTags = styled.div`
  display: flex;
  gap: 0.25rem;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colorText_body_3};
`;

export const StyledDetailSns = styled.div`
  h3 {
    margin-bottom: 1rem;
    ${(props) => props.theme.typography.title3};
    color: ${(props) => props.theme.colorText_body};
  }

  .sns-items {
    display: flex;
    justify-content: space-between;
    ${({ theme }) => theme.media.laptop} {
      padding: 0 120px;
    }
  }

  .sns-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    ${(props) => props.theme.typography.body2}
    color: ${(props) => props.theme.colorText_body_3};
    background: transparent;
    border: 0;

    i {
      display: block;
      width: 44px;
      height: 44px;
      ${({ theme }) => theme.media.desktop} {
        width: 54px;
        height: 54px;
      }
    }

    span {
      display: block;
      margin-top: 12px;
    }
  }
`;

export const StyledContractCsCardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.5rem;
  text-align: left;
  background: #fff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 2px 12px rgb(0 0 0 / 6%);
`;

export const StyledContractContent = styled.div`
  padding: 0 1.25rem 2rem;
  ${({ theme }) => theme.media.desktop} {
    max-width: 34.5rem;
    padding: 4rem 0 3rem;
    margin: 0 auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border-top: 1px solid black;

  th,
  td {
    min-height: 50px;
    padding: 8px;
    ${({ theme }) => theme.typography.caption};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorder_3};
  }

  tbody tr th {
    text-align: center;
    border-right: 1px solid ${({ theme }) => theme.colorBorder_3};
  }

  thead > tr th {
    min-height: 50px;
    padding: 10px;
    text-align: center;
    background-color: #f5f6f7;
    border-bottom: 1px solid ${({ theme }) => theme.colorBorder_3};

    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.colorBorder_3};
    }
  }
`;

/**
 * 완료 페이지 등 하단에 들어가는 고객센터, 바로가기 배너
 */
export const StyledBottomBanner = styled.div<{ href?: string }>`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.spacing_large};
  background-color: ${({ theme }) => theme.colorSurface_neutral};
  border-radius: ${({ theme }) => theme.radius.borderRadius_xLarge};
  ${({ theme }) => theme.shadow.boxShadow_large};

  ${({ theme }) => theme.media.laptop} {
    &[href^='tel:'] {
      pointer-events: none;
    }
  }
`;

/**
 * 카드 요소
 */
export const StyledCustomCard = styled(Box)`
  ${({ theme }) => theme.shadow.boxShadow_small}

  &:hover {
    box-shadow: inset 100rem 100rem rgb(0 0 0 / 2%);
  }

  &:active {
    box-shadow: inset 100rem 100rem rgb(0 0 0 / 2%);
  }

  &:disabled {
    opacity: 0.6;
  }
`;

export const StyledCardHeader = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;

  .card-title {
    flex: 1;
  }
`;
export const StyledCardBody = styled.div``;
export const StyledCardFooter = styled.div`
  margin-top: 16px;
`;

/**
 * 순서 목록 스타일
 */
export const StyledOrderList = styled.ol``;
export const StyledOrderListItem = styled.li`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0.75rem 0;
  counter-increment: step-counter;
  ${({ theme }) => theme.typography.subtitle2};

  &::before {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    font-size: ${({ theme }) => theme.fontSize.fontSize_text_small};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight_bold};
    line-height: ${({ theme }) => theme.lineHeight.fontLineHeight_large};
    color: #5979c9;
    content: counter(step-counter);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23E0EBFC'/%3E%3C/svg%3E")
      no-repeat;
    background-size: cover;
  }
`;

/**
 * 절차 목록 스타일
 */
export const StyledStepList = styled.ol`
  /* margin-top: 2rem; */
`;
export const StyledStepListItem = styled.li`
  position: relative;
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
  padding: 1rem 0;

  &::before {
    position: absolute;
    top: 16px;
    left: 22px;
    z-index: 1;
    display: block;
    width: 2px;
    height: 100%;
    content: '';
    background-color: #f5f6f7;
  }

  &:last-child {
    &::before {
      display: none;
    }
  }

  .icon-box {
    z-index: 1;
    flex-shrink: 0;
  }

  .title {
    ${({ theme }) => theme.typography.subtitle2}
  }

  .description {
    margin-top: 0.25rem;
    color: ${({ theme }) => theme.colorText_body_2};
    ${({ theme }) => theme.typography.body2}
  }
`;

/**
 * 보안 키패드용 주민등록번호 입력필드
 */
export const StyledIdCardNumberFormField = styled.div`
  /* 보안 키패드 사용 input을 위한 보안키패드 활성화 시, readOnly 상태에서 focus 상태로 보여주기 위한 클래스 */
  .is-focused {
    border-color: ${({ theme }) => theme.colorSurface_secondary} !important;
  }
`;

export const StyledIdCardNumberMultiInputWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const StyledCsCallCenterListWrap = styled.div`
  ${({ theme }) => theme.media.laptop} {
    .pc-none-click {
      pointer-events: none;
    }
  }
`;
export const StyledCsCallCenterListItem = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  .left-wrap {
    display: flex;
    flex: 1;
    gap: 0.75rem;
    align-items: center;
  }

  .text-wrap {
    display: flex;
    flex-direction: column;
  }

  .title {
    ${({ theme }) => theme.typography.subtitle2}
    color: ${({ theme }) => theme.colorText_body};
  }

  .description {
    margin-top: 0.25rem;
    ${({ theme }) => theme.typography.body2}
    color: ${({ theme }) => theme.colorText_body_2};
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  ${({ theme }) => theme.media.laptop} {
    .arrow-icon {
      display: none;
    }
  }
`;

export const StyledAppStoreLink = styled.a`
  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 56px;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.colorSurface_neutral};
  border: 1px solid ${({ theme }) => theme.colorBorder_3};
  border-radius: ${({ theme }) => theme.radius.borderRadius_medium};

  &:hover {
    @media (hover: hover) and (pointer: fine) {
      background-color: ${({ theme }) => theme.colorSurface_neutral_1};
      transition: background-color 0.2s ease-out;
    }
  }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zindex.modal};
  width: 100%;
  height: 100%;
  visibility: hidden;
  background: ${({ theme }) => theme.colorBackground_dimmed};
  opacity: 0;
  transition: opacity ease-in-out 0.4s;

  &.opened {
    visibility: visible;
    opacity: 1;
  }
`;
