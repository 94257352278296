const data = {
  dev: {
    fnbMenu: [
      {
        menuName: '개인정보처리방침',
        menuUrl: 'https://dev-direct.hanwhalife.com/app/terms/personal-infor',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '고객권리안내문',
        menuUrl: 'https://dev-direct.hanwhalife.com/app/terms/client-manager',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '이용약관',
        menuUrl: 'https://dev-direct.hanwhalife.com/app/terms/user-agreements',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '전자거래이용약관',
        menuUrl: 'https://dev-direct.hanwhalife.com/app/terms/ecommerce',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '보험금융상품등록부',
        menuUrl: 'https://www.hanwhalife.com/main/disclosure/protectfinanceregistration/DF_PFCR000_T10000.do',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '지적재산권 및 법적한계',
        menuUrl: 'https://dev-direct.hanwhalife.com/app/terms/intellectual-property',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '신용정보활용체제',
        menuUrl: 'https://dev-direct.hanwhalife.com/app/terms/credit-infor',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '이메일수집거부',
        menuUrl: 'https://dev-direct.hanwhalife.com/app/terms/email-collect',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      }
    ]
  },
  stage: {
    fnbMenu: [
      {
        menuName: '개인정보처리방침',
        menuUrl: 'https://stg-direct.hanwhalife.com/app/terms/personal-infor',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '고객권리안내문',
        menuUrl: 'https://stg-direct.hanwhalife.com/app/terms/client-manager',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '이용약관',
        menuUrl: 'https://stg-direct.hanwhalife.com/app/terms/user-agreements',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '전자거래이용약관',
        menuUrl: 'https://stg-direct.hanwhalife.com/app/terms/ecommerce',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '보험금융상품등록부',
        menuUrl: 'https://www.hanwhalife.com/main/disclosure/protectfinanceregistration/DF_PFCR000_T10000.do',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '지적재산권 및 법적한계',
        menuUrl: 'https://stg-direct.hanwhalife.com/app/terms/intellectual-property',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '신용정보활용체제',
        menuUrl: 'https://stg-direct.hanwhalife.com/app/terms/credit-infor',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '이메일수집거부',
        menuUrl: 'https://stg-direct.hanwhalife.com/app/terms/email-collect',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      }
    ]
  },
  prod: {
    fnbMenu: [
      {
        menuName: '개인정보처리방침',
        menuUrl: 'https://direct.hanwhalife.com/app/terms/personal-infor',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '고객권리안내문',
        menuUrl: 'https://direct.hanwhalife.com/app/terms/client-manager',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '이용약관',
        menuUrl: 'https://direct.hanwhalife.com/app/terms/user-agreements',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '전자거래이용약관',
        menuUrl: 'https://direct.hanwhalife.com/app/terms/ecommerce',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '보험금융상품등록부',
        menuUrl: 'https://www.hanwhalife.com/main/disclosure/protectfinanceregistration/DF_PFCR000_T10000.do',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '지적재산권 및 법적한계',
        menuUrl: 'https://direct.hanwhalife.com/app/terms/intellectual-property',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '신용정보활용체제',
        menuUrl: 'https://direct.hanwhalife.com/app/terms/credit-infor',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      },
      {
        menuName: '이메일수집거부',
        menuUrl: 'https://direct.hanwhalife.com/app/terms/email-collect',
        imageUrl: '',
        permission: 'A',
        subMenu: []
      }
    ]
  },
  familySite: [
    {
      menuName: '한화생명',
      menuUrl: 'https://www.hanwhalife.com',
      imageUrl: '',
      permission: 'A',
      subMenu: []
    },
    {
      menuName: '한화손해보험',
      menuUrl: 'https://www.hwgeneralins.com',
      imageUrl: '',
      permission: 'A',
      subMenu: []
    },
    {
      menuName: '한화저축은행',
      menuUrl: 'https://www.hanwhasbank.com',
      imageUrl: '',
      permission: 'A',
      subMenu: []
    },
    {
      menuName: '한화자산운용',
      menuUrl: 'https://www.hanwhafund.co.kr',
      imageUrl: '',
      permission: 'A',
      subMenu: []
    },
    {
      menuName: '한화투자증권',
      menuUrl: 'https://www.hanwhawm.com/',
      imageUrl: '',
      permission: 'A',
      subMenu: []
    },
    {
      menuName: '캐롯손해보험',
      menuUrl: 'https://www.carrotins.com',
      imageUrl: '',
      permission: 'A',
      subMenu: []
    }
  ]
};
export default data;
