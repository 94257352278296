import { create } from '@hanwhalife/shared-libs/zustand';
import { ILoginInfo } from '@hanwhalife/ts-types';

/** 로그인 정보 초기 값 */
const initialState: ILoginInfo = {
  // User Info
  BIOSHAMIN: '',
  IP: '',
  MASKMIN: '',
  MIN: '',
  NAME: '',
  NKID: '',
  birthWithResidentRegistrationNumberLastDigit: '',
  age: -1,
  callFrom: '',
  categoryNumber: '',
  fullHP: '',
  gender: '',
  hp_1: '',
  hp_2: '',
  hp_3: '',
  insuFeeCode: '',
  insuFeeMsg: '',
  insuNumber: '',
  itemNumber: '',
  location: '',
  locationSub: '',
  CS_STR_122: '',
  integrationMemberYn: undefined,
  customerYn: undefined,
  integrationMemberId: '',
  integrationMemberName: '',
  customerId: '',
  memberStatus: undefined,
  memberType: 'A',
  phoneNumber: '',
  joinDateTime: '',
  integrationMemberBirthWithResidentRegistrationNumberLastDigit: '',
  integrationMemberJoinDateTime: '',
  isIdentityConfirmationTarget: undefined,
  isMobilePhoneNumberMatch: undefined,
  isTruthOrFalsehoodConfirmation: undefined,
  maskedCustomerMobilePhoneNumber: '',
  maskedIntegrationMemberEmail: '',
  maskedIntegrationMemberMobilePhoneNumber: '',

  // Auth info
  loginType: '',
  tagNumber: '',
  accessToken: '',
  refreshToken: '',
  tokenType: '',
  expireIn: 0,
  certType: undefined,
  loginChannel: '',

  // device info
  deviceID: '',
  platformName: '',
  platformVersion: '',
  appVer: '',
  carrierName: '',
  networkType: '',
  modelName: '',
  platform: undefined,
  isNative: false,

  // Login
  account: {
    login: 'no',
    name: '',
    nkid: '',
    lastAccessTime: ''
  },
  isLogined: false,
  isMember: false,
  isInitLoginInfo: false
};

interface ILoginInfoState {
  /** login 정보, device 정보 */
  loginInfo: ILoginInfo;
  setLoginInfo: (loginInfo: Partial<ILoginInfo>) => void;
  initLoginInfo: () => void;
}

/**
 * Login Info
 */
export const useLoginInfo = create<ILoginInfoState>((set) => ({
  loginInfo: initialState,
  setLoginInfo: (loginInfo) => {
    console.log('[Zustand::info] updated loginInfo>>>>>>>>>>>>>>> ', loginInfo);

    set((state) => ({ loginInfo: { ...state.loginInfo, ...loginInfo } }));
  },
  initLoginInfo() {
    console.log('[Zustand::info] loginInfo initLoginInfo>>>>>>>>>>>>>>> ');

    set(() => ({ loginInfo: initialState }));
  }
}));
