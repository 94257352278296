import { MediaQuery, ThemeGroup } from '@hanwhalife/shared-libs/design-system';
import { Global, css } from '@hanwhalife/shared-libs/emotion/react';

/**
 * 최상위 root, reset 스타일
 */
export const rootCss = css`
  html,
  body,
  #root,
  #__next,
  #storybook-root {
    height: 100%;
  }

  .init-button {
    padding: 0;
    color: ${ThemeGroup.light.colorText_body};
    background-color: transparent;
    border: 0;
  }

  ${MediaQuery.laptop} {
    #root,
    #__next,
    #storybook-root {
      min-height: 1080px;
    }
  }
`;

/**
 * 반응형 스타일
 */
export const responsiveCss = css`
  .is-pc {
    display: none;
  }

  .pc-br {
    display: none;
  }

  .pre-line {
    white-space: pre-line;
  }

  /* // * DS 컴포넌트 자체 여백과 기본 화면 레이아웃과 겹침을 해결하기 위한 클래스 */
  .content-full-width {
    margin-right: -20px;
    margin-left: -20px;
  }

  .tel-link {
    color: ${ThemeGroup.light.colorText_body};
    text-decoration: underline;
  }

  /* // TODO: DS ListDefaultItem hover 제거 className="none-hover" 추가 , 정책 정의 후 제거 필요 */
  .none-hover:hover {
    box-shadow: none !important;
  }

  ${MediaQuery.laptop} {
    .is-pc {
      display: block;
    }

    .is-mobile {
      display: none;
    }

    .pc-br {
      display: block;
    }

    .mobile-br {
      display: none;
    }

    .content-full-width {
      margin-right: 0;
      margin-left: 0;
    }

    .tel-link {
      text-decoration: none;
      pointer-events: none;
    }
  }
`;

/**
 * portal 관련 스타일
 */
export const portalCss = css`
  /* // * DS Modal에서 컴포넌트가 교체되는 방식으로 사용하는 케이스 간격 대응 ex) 통합간편인증, 사고보험금청구 내역조회상세 등 */
  .modal-body-wrapper {
    display: flex;
    flex-direction: column;
  }

  /* // TODO: DS Toast 컴포넌트 position 값 조정 클래스 방식으로 적용 */
  .toast-container {
    top: 52px !important;
  }

  .bottom-toast-container {
    bottom: 88px !important;
  }

  ${MediaQuery.laptop} {
    .toast-container {
      top: 72px !important;
    }

    .bottom-toast-container {
      bottom: 0 !important;
    }
  }
`;

/**
 * animation 관련 스타일
 */
export const animationCss = css`
  #ds-modal-ani {
    opacity: 0;
    animation: fade-in 0.4s ease-in-out forwards;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

/**
 * 글로벌 스타일 컴포넌트
 */
export const GlobalStyled = (): React.ReactElement => {
  return (
    <Global
      styles={css`
        ${rootCss}
        ${responsiveCss}
        ${portalCss}
        ${animationCss}
      `}
    />
  );
};
